import React, { useState } from 'react'
import { Text } from 'rebass'
import { CloseIcon } from '../../theme'
import Column from '../Column'
// import ListLogo from '../ListLogo'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { PaddedColumn, Separator } from './styleds'
import Modal from '../Modal'
import { ButtonLight } from '../../components/Button'
import { Box } from '@material-ui/core'
import axios from 'axios'
import { useActiveWeb3React } from '../../hooks'
import { BACKEND } from '../../constants'

interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void,
  setKYC: any
}

const Input = styled.input<{ error?: boolean }>`
  font-size: 14px;
  outline: none;
  border: none;
  flex: 1 1 auto;
  min-width: 250px;
  border-radius: 10px;
  width: 0;
  background-color: #252525;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : theme.primary1)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: #757575;
    font-size: 14px;
  }
  padding: 10px 8px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: #757575;
    font-size: 14px;
  }
`
export function KycModal({ onDismiss, isOpen, setKYC }: CurrencySearchProps) {
  const { account } = useActiveWeb3React()
  const [name, setName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [SSN, setSSN] = useState<string>("")
  const [nation, setNation] = useState<string>("")

  const sumbitKYC = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const kyc = {
      address: account,
      name,
      email,
      phone,
      SSN,
      nation
    }

    const { data } = await axios.post(`${BACKEND}/kyc`, kyc)
    if (data.status) {
      setKYC(true)
    }

  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} >
      <Column style={{ width: '100%', flex: '1 1', backgroundColor: '#131313' }}>
        <PaddedColumn gap="5px">
          <RowBetween>
            <Text fontWeight={500} fontSize={16}>
              KYC Registration
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <Text fontWeight={500} fontSize={14} color={'#C7CAD9'}>
            To complete the KYC registration process, you must provide the required information.
          </Text>
        </PaddedColumn>
        <form onSubmit={sumbitKYC} action="post">
          <PaddedColumn gap="15px">
            <Box>
              <Input className="recipient-address-input" type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} required />
              <Text fontWeight={400} fontSize={12} paddingTop={'3px'} color={'#696C80'} >
                Please enter your full legal name.
              </Text>
            </Box>
            <Box>
              <Input className="recipient-address-input" type="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} value={email} required />
              <Text fontWeight={400} fontSize={12} paddingTop={'3px'} color={'#696C80'}>
                Please enter your email address.
              </Text>
            </Box>
            <Box>
              <Input className="recipient-address-input" type="number" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} value={phone} required />
              <Text fontWeight={400} fontSize={12} paddingTop={'3px'} color={'#696C80'}>
                Please enter your phone number.
              </Text>
            </Box>
            <Box>
              <Input className="recipient-address-input" type="text" placeholder="Nationality" onChange={(e) => setNation(e.target.value)} value={nation} required />
              <Text fontWeight={400} fontSize={12} paddingTop={'3px'} color={'#696C80'}>
                Please enter your nationality.
              </Text>
            </Box>
            <Box>
              <Input
                className="recipient-address-input"
                type="text"
                placeholder="Passport or Social Security Number"
                onChange={(e) => setSSN(e.target.value)} value={SSN}
                required
              />
              <Text fontWeight={400} fontSize={12} paddingTop={'3px'} color={'#696C80'}>
                Please enter your Passport or Social security number.
              </Text>
            </Box>
            <Box>
              <label style={{ cursor: 'pointer', userSelect: 'none', fontSize: '14px', fontWeight: 400, }}>
                <input
                  type="checkbox"
                  className="understand-checkbox"
                //   checked={understandChecked}
                //   onChange={toggleUnderstand}
                />
                <span style={{ marginLeft: '10px', marginRight: '5px' }}>I have read and agree to the</span>
                <a style={{ color: '#448AFF', fontSize: '14px', fontWeight: 500 }}>user agreement↗</a>
              </label>
            </Box>
            <ButtonLight
              type="submit"
              style={{
                padding: '12px 0px',
                maxWidth: '100%',
                fontSize: '18px',
                borderRadius: '10px',
                fontWeight: 600,
                margin: '10px 0px'
              }}
            >
              Submit
            </ButtonLight>
          </PaddedColumn>
        </form>

        <Separator />
      </Column>
    </Modal>
  )
}
