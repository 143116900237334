import { CurrencyAmount, JSBI, Token, Trade } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import axios from 'axios'
import { Button, Text } from 'rebass'

import styled, { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import { BACKEND, ChainN, HexChainId, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { Box, Grid, Link, useMediaQuery, createTheme } from '@material-ui/core'

import Copy from '../../components/AccountDetails/Copy'
import Settings from '../../components/Settings'

import ArrowLeft from '../../assets/svg/arrow-left.svg'
import ArrowRight from '../../assets/svg/arrow-right.svg'
import CrowImg from '../../assets/svg/crow.svg'
import BitloImg from '../../assets/svg/bitlo.svg'
import CointrImg from '../../assets/svg/cointr.svg'
import TwitterImg from '../../assets/svg/twitter.svg'
import NewsImg from '../../assets/svg/news.svg'

import CROWIMG from '../../assets/tokens/crow.svg'
import CROCIMG from '../../assets/tokens/croc.svg'
import CROFIMG from '../../assets/tokens/crof.svg'
import CROBIMG from '../../assets/tokens/crob.svg'
import { KycModal } from '../../components/SearchModal/KycModal'
import toast, { Toaster } from 'react-hot-toast'

// import { auto } from '@popperjs/core'
const Input = styled.input<{ error?: boolean }>`
  font-size: 1.25rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  min-width: 250px;
  border-radius: 10px;
  width: 0;
  background-color: ${({ theme }) => theme.bg1};
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : theme.primary1)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
  padding: 8px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`
const Tokens = [
  {
    id: 'cropto-wheat-token',
    name: 'WHEAT TOKEN',
    symbol: 'CROW',
    price: 0,
    percent: 0,
    img: CROWIMG,
    contract: '0x0da0bd2f57a27a70665d53db4ea71e1f26f77a46',
    chart: 'https://www.coingecko.com/coins/35306/sparkline.svg'
  },
  {
    id: 'cropto-barley-token',
    name: 'BARLEY TOKEN',
    symbol: 'CROB',
    price: 0,
    percent: 0,
    img: CROBIMG,
    contract: '0xc3211f7eb806e916d54a2a166fc36188cffde25b',
    chart: 'https://www.coingecko.com/coins/35188/sparkline.svg'
  },
  {
    id: 'cropto-corn-token',
    name: 'CORN TOKEN',
    symbol: 'CROC',
    price: 0,
    percent: 0,
    img: CROCIMG,
    contract: '0x0735fa49eb7d9ddf3e4d9a9f01229627f67632a1',
    chart: 'https://www.coingecko.com/coins/35189/sparkline.svg'
  },
  {
    id: 'cropto-hazelnut-token',
    name: 'HAZELNUT TOKEN',
    symbol: 'CROF',
    price: 0,
    percent: 0,
    img: CROFIMG,
    contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
    chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  },
  {
    id: 'cropto-hazelnut-token',
    name: 'Apricot TOKEN',
    symbol: 'CROD',
    price: 0,
    percent: 0,
    img: CROFIMG,
    contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
    chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  },
  {
    id: 'cropto-hazelnut-token',
    name: 'Bean TOKEN',
    symbol: 'CROH',
    price: 0,
    percent: 0,
    img: CROFIMG,
    contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
    chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  }
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Chickpea TOKEN',
  //   symbol: 'CRON',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Cotton TOKEN',
  //   symbol: 'CROK',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },

  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Flaxseed TOKEN',
  //   symbol: 'CROX',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Lentil TOKEN',
  //   symbol: 'CROL',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Oat TOKEN',
  //   symbol: 'CROY',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Olive TOKEN',
  //   symbol: 'CROO',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Rice TOKEN',
  //   symbol: 'CROR',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Rye TOKEN',
  //   symbol: 'CROV',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'Pistachio TOKEN',
  //   symbol: 'CROG',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
  // {
  //   id: 'cropto-hazelnut-token',
  //   name: 'SoybeanMeal TOKEN',
  //   symbol: 'CROM',
  //   price: 0,
  //   percent: 0,
  //   img: CROFIMG,
  //   contract: '0xa9c992952c2090a51506c4f3636c1320f8fa93fa',
  //   chart: 'https://www.coingecko.com/coins/35244/sparkline.svg'
  // },
]
export default function Swap() {
  console.log("swap")
  const isMobile = useMediaQuery('(max-width:1260px)')
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [prices, setPrices] = useState<any>()
  const [arrowLeft, setArrowLeft] = useState(!isMobile)
  const [arrowRight, setArrowRight] = useState(!isMobile)
  // const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [kyc, setKyc] = useState<boolean>(true)

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const handleClose = () => {
    setKyc(false)
  }

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const { ethereum } = window

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }

  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]

  const parsedAmounts = showWrap
    ? {
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedAmount
    }
    : {
      [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
      [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
    }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
                ? 'Swap w/o Send + recipient'
                : 'Swap w/ Send',
          label: [trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const addMaticToMetamask = () => {
    if (ethereum) {
      // @ts-ignore
      ethereum
        // @ts-ignore
        ?.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: HexChainId
            }
          ]
        })
        .then((result: any) => { })
        .catch((error: any) => {
          console.log(error, 'error')
          ethereum
            // @ts-ignore
            ?.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: HexChainId,
                  chainName: 'Matic Network',
                  rpcUrls: ['https://rpc-mumbai.maticvigil.com"'],
                  blockExplorerUrls: ['https://amoy.polygonscan.com/'],
                  nativeCurrency: {
                    name: 'Matic Token',
                    symbol: 'MATIC',
                    decimals: 18
                  }
                }
              ] // you must have access to the specified account
            })
            .then((result: any) => { })
            .catch((error: any) => {
              if (error.code === 4001) {
                // EIP-1193 userRejectedRequest error
                console.log('We can encrypt anything without the key.')
              } else {
                console.error(error)
              }
            })
        })
    }
  }

  const loadCoins = async () => {
    let coins = {}
    for (let index = 0; index < Tokens.length; index++) {
      const item = Tokens[index]
      const response = await (
        await fetch(
          `https://pro-api.coingecko.com/api/v3/coins/${item.id}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=true&sparkline=false&x_cg_pro_api_key=CG-LwkQWwWDxT75R7oyV6C5dsjz`
        )
      ).json()
      coins = {
        ...coins,
        [item.id]: {
          percent: response.market_data.price_change_percentage_24h,
          price: response.market_data.current_price.usd
        }
      }
    }

    setPrices(coins)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const url = `https://themindstone.us13.list-manage.com/subscribe/post`

    const formdata = new FormData()
    formdata.append('u', '96aa7136cf00eb2435639f4e2')
    formdata.append('id', '8e05ed24f8')
    formdata.append('f_id', '00de9be8f0')
    formdata.append('EMAIL', email)

    fetch(url, { method: 'POST', body: formdata, mode: 'no-cors' })
      .then(response => {
        toast.success('Subscribe Sucessfully!')
      })
      .catch(error => {
        toast.error(error)
      })
  }

  useEffect(() => {
    loadCoins()
  }, [])

  const theme1 = createTheme()

  // Use useMediaQuery hook to check screen size
  const isSmallScreen = useMediaQuery(theme1.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme1.breakpoints.between('sm', 'lg'))
  const isLargeScreen = useMediaQuery(theme1.breakpoints.up('xl'))

  const InputContainer = styled.div`
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
      background: #f6f6f6;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: #f6f6f6;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: #f6f6f6;
      background: #1aab25;
    }
  `

  const check = async () => {
    const { data } = await axios.post(`${BACKEND}/check`, { address: account })
    setKyc(data.status)
  }

  useEffect(() => {
    if (account) {
      check()
    }
  }, [account])
  // const Desktopview = useMediaQuery('(min-width:1200px)');

  return (
    <>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <Grid container spacing={1} >
        {!isMobile && (
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Box display="flex" style={{ gap: '8px', justifyContent: !arrowLeft ? 'flex-end' : 'initial' }}>
              <Button
                style={{
                  background: theme.bg6,
                  borderRadius: '12px',
                  height: '48px',
                  width: '48px',
                  cursor: 'pointer',
                  display: isMobile ? 'none' : 'initial'
                }}
                onClick={() => setArrowLeft(!arrowLeft)}
              >
                <img src={arrowLeft ? ArrowLeft : ArrowRight}></img>
              </Button>
              {arrowLeft && (
                <AppBody>
                  <Box
                    display="flex"
                    style={{
                      textTransform: 'none',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 600,
                      height: '47px'
                    }}
                    pb={1.5}
                  >
                    Tokens
                  </Box>
                  {/* <InputContainer> */}
                  <InputContainer style={{ gap: '16px', flexDirection: 'column', display: 'flex', maxHeight: '473px' }}>
                    {prices &&
                      Tokens.map((item, key) => {
                        return (
                          <Box sx={{ border: `1px solid ${theme.bg1}`, borderRadius: '10px' }} key={key}>
                            <Box
                              p={2}
                              display="flex"
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '14px'
                              }}
                            >
                              <Box
                                display="flex"
                                style={{
                                  alignItems: 'start',
                                  justifyContent: 'space-between',
                                  gap: '5px'
                                }}
                              >
                                <img src={item.img} />
                                {item.symbol}
                              </Box>

                              <Box>
                                <Box sx={{ color: '#696C80', fontWeight: 600 }}>Price</Box>
                                <Box>${Number(prices[item.id]?.price).toFixed(3)}</Box>
                              </Box>

                              <Box>
                                <Box sx={{ color: '#696C80', fontWeight: 600 }}>24h</Box>
                                <Box sx={{ color: prices[item.id]?.percent > 0 ? '#0FC679' : '#ff3a33' }}>
                                  {Number(prices[item.id]?.percent).toFixed(3)}%
                                </Box>
                              </Box>

                              <img src={item.chart} style={{ width: '70px' }} />
                            </Box>

                            <Box
                              px={2}
                              py={1}
                              display="flex"
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                background: theme.bg1,
                                border: `1px solid ${theme.text6}`
                              }}
                            >
                              <Box style={{ fontSize: '14px' }}>{item.name}</Box>
                              <Copy toCopy={item.contract}>
                                <span style={{ marginLeft: '4px' }}>
                                  {item.contract.substring(0, 5)}...{item.contract.substring(item.contract.length - 5)}
                                </span>
                              </Copy>
                            </Box>
                          </Box>
                        )
                      })}
                  </InputContainer>
                  {/* </InputContainer> */}
                </AppBody>
              )}
            </Box>
          </Grid>
        )}

        <Grid item lg={4} md={12} sm={12} xs={12}>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
            <AppBody>
              <Box
                display="flex"
                style={{
                  textTransform: 'none',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontWeight: 600
                }}
                pb={1.5}
              >
                Swap
                <Settings />
              </Box>
              <SwapPoolTabs active={'swap'} />
              <Wrapper id="swap-page">
                <ConfirmSwapModal
                  isOpen={showConfirm}
                  trade={trade}
                  originalTrade={tradeToConfirm}
                  onAcceptChanges={handleAcceptChanges}
                  attemptingTxn={attemptingTxn}
                  txHash={txHash}
                  recipient={recipient}
                  allowedSlippage={allowedSlippage}
                  onConfirm={handleSwap}
                  swapErrorMessage={swapErrorMessage}
                  onDismiss={handleConfirmDismiss}
                />

                <AutoColumn gap={'1px'}>
                  <CurrencyInputPanel
                    label={independentField === Field.OUTPUT && !showWrap && trade ? 'You pay (estimated)' : 'You pay'}
                    value={formattedAmounts[Field.INPUT]}
                    showMaxButton={!atMaxAmountInput}
                    currency={currencies[Field.INPUT]}
                    onUserInput={handleTypeInput}
                    onMax={handleMaxInput}
                    onCurrencySelect={handleInputSelect}
                    otherCurrency={currencies[Field.OUTPUT]}
                    id="swap-currency-input"
                  />
                  <Box
                    style={{
                      position: 'relative',
                      zIndex: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AutoColumn justify="space-between" style={{ position: 'absolute' }}>
                      <AutoRow
                        justify={isExpertMode ? 'space-between' : 'center'}
                        style={{
                          padding: '4px 6px',
                          // height: '32px',
                          // width: '32px',
                          border: `4px solid ${theme.bg6}`,
                          borderRadius: '12px',
                          position: 'relative',
                          background: theme.bg1
                        }}
                      >
                        <ArrowWrapper clickable>
                          <ArrowDown
                            size="16"
                            onClick={() => {
                              setApprovalSubmitted(false) // reset 2 step UI for approvals
                              onSwitchTokens()
                            }}
                            color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                          />
                        </ArrowWrapper>
                        {recipient === null && !showWrap && isExpertMode ? (
                          <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                            + Add a send (optional)
                          </LinkStyledButton>
                        ) : null}
                      </AutoRow>
                    </AutoColumn>
                  </Box>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.OUTPUT]}
                    onUserInput={handleTypeOutput}
                    label={
                      independentField === Field.INPUT && !showWrap && trade ? 'You receive (estimated)' : 'You receive'
                    }
                    showMaxButton={false}
                    currency={currencies[Field.OUTPUT]}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={currencies[Field.INPUT]}
                    id="swap-currency-output"
                  />

                  {recipient !== null && !showWrap ? (
                    <>
                      <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                        <ArrowWrapper clickable={false}>
                          <ArrowDown size="16" color={theme.text2} />
                        </ArrowWrapper>
                        <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                          - Remove send
                        </LinkStyledButton>
                      </AutoRow>
                      <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                    </>
                  ) : null}

                  {showWrap ? null : (
                    <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                      <AutoColumn gap="4px">
                        {Boolean(trade) && (
                          <RowBetween align="center">
                            <Text fontWeight={500} fontSize={14} color={theme.text2}>
                              Price
                            </Text>
                            <TradePrice
                              price={trade?.executionPrice}
                              showInverted={showInverted}
                              setShowInverted={setShowInverted}
                            />
                          </RowBetween>
                        )}
                        {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                          <RowBetween align="center">
                            <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                              Slippage Tolerance
                            </ClickableText>
                            <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                              {allowedSlippage / 100}%
                            </ClickableText>
                          </RowBetween>
                        )}
                      </AutoColumn>
                    </Card>
                  )}
                </AutoColumn>
                {trade && <AdvancedSwapDetailsDropdown trade={trade} />}

                <BottomGrouping>
                  {!account ? (
                    <ButtonLight
                      onClick={
                        ethereum && ethereum.isMetaMask && Number(ethereum.chainId) !== ChainN
                          ? addMaticToMetamask
                          : toggleWalletModal
                      }
                    >
                      {ethereum && ethereum.isMetaMask && Number(ethereum?.chainId) !== ChainN
                        ? 'Switch to Matic'
                        : 'Connect Wallet'}
                    </ButtonLight>
                  ) : showWrap ? (
                    <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                      {wrapInputError ??
                        (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                    </ButtonPrimary>
                  ) : noRoute && userHasSpecifiedInputOutput ? (
                    <GreyCard style={{ textAlign: 'center' }}>
                      <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
                    </GreyCard>
                  ) : showApproveFlow ? (
                    <RowBetween>
                      <ButtonConfirmed
                        onClick={approveCallback}
                        disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                        width="48%"
                        altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                        confirmed={approval === ApprovalState.APPROVED}
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          'Approve ' + currencies[Field.INPUT]?.symbol
                        )}
                      </ButtonConfirmed>
                      <ButtonError
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined
                            })
                          }
                        }}
                        width="48%"
                        id="swap-button"
                        disabled={
                          !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                        }
                        error={isValid && priceImpactSeverity > 2}
                      >
                        <Text fontSize={16} fontWeight={500}>
                          {priceImpactSeverity > 3 && !isExpertMode
                            ? `Price Impact High`
                            : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                        </Text>
                      </ButtonError>
                    </RowBetween>
                  ) : (
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined
                          })
                        }
                      }}
                      id="swap-button"
                      disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                      error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                    >
                      <Text fontSize={20} fontWeight={500}>
                        {swapInputError
                          ? swapInputError
                          : priceImpactSeverity > 3 && !isExpertMode
                            ? `Price Impact Too High`
                            : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                      </Text>
                    </ButtonError>
                  )}
                  {showApproveFlow && (
                    <Column style={{ marginTop: '1rem' }}>
                      <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                    </Column>
                  )}
                  {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                </BottomGrouping>
              </Wrapper>
            </AppBody>

            {!isMobile && (
              <AppBody>
                <Box
                  display="flex"
                  style={{
                    textTransform: 'none',
                    alignItems: 'center',
                    fontWeight: 600,
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '5px',
                    padding: ' 3px'
                  }}
                // pb={1.5}
                >
                  <Box style={{ color: '#696C80', fontSize: '13px' }}>JOIN OUR COMMUNITY</Box>
                  <Box style={{ color: theme.text7, fontSize: '21px', fontWeight: 700 }}>
                    Cropto Official Newsletter
                  </Box>
                  <Box style={{ color: '#696C80', fontSize: '13px' }}>
                    Sign-up for the Cropto newsletter to get latest news
                  </Box>
                  <form name="mc-embedded-subscribe-form" onSubmit={onSubmit} style={{ width: '100%' }}>
                    <Box
                      style={{
                        display: 'flex',
                        gap: '10px',
                        paddingTop: '5px',
                        width: '100%'
                      }}
                    >
                      <Input
                        className="recipient-address-input"
                        style={{
                          fontSize: isSmallScreen ? '12px' : isMediumScreen ? '15px' : isLargeScreen ? '15px' : '15px'
                        }}
                        type="email"
                        name="EMAIL"
                        placeholder="Enter your email"
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)}
                      />
                      <ButtonLight
                        style={{
                          padding: '8px',
                          maxWidth: '150px',
                          fontSize: isSmallScreen ? '12px' : isMediumScreen ? '15px' : isLargeScreen ? '15px' : '15px'
                        }}
                        type="submit"
                      >
                        Subscribe
                      </ButtonLight>
                    </Box>
                  </form>
                </Box>
              </AppBody>
            )}
          </Box>
        </Grid>
        {isMobile && (
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Box display="flex" style={{ gap: '8px', justifyContent: !arrowLeft ? 'flex-end' : 'center' }}>
              <Button
                style={{
                  background: theme.bg6,
                  borderRadius: '12px',
                  height: '48px',
                  width: '48px',
                  cursor: 'pointer',
                  display: isMobile ? 'none' : 'initial'
                }}
                onClick={() => setArrowLeft(!arrowLeft)}
              >
                <img src={arrowLeft ? ArrowLeft : ArrowRight}></img>
              </Button>
              {arrowLeft && (
                <AppBody>
                  <Box
                    display="flex"
                    style={{
                      textTransform: 'none',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 600,
                      height: '47px'
                    }}
                    pb={1.5}
                  >
                    Tokens
                  </Box>
                  <InputContainer style={{ gap: '16px', flexDirection: 'column', display: 'flex', maxHeight: '465px' }}>
                    {prices &&
                      Tokens.map((item, key) => {
                        return (
                          <Box sx={{ border: `1px solid ${theme.bg1}`, borderRadius: '10px' }} key={key}>
                            <Box
                              p={2}
                              display="flex"
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '14px'
                              }}
                            >
                              <Box
                                display="flex"
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: '5px'
                                }}
                              >
                                <img src={item.img} />
                                {item.symbol}
                              </Box>

                              <Box>
                                <Box sx={{ color: '#696C80', fontWeight: 600 }}>Price</Box>
                                <Box>${Number(prices[item.id]?.price).toFixed(3)}</Box>
                              </Box>

                              <Box>
                                <Box sx={{ color: '#696C80', fontWeight: 600 }}>24h</Box>
                                <Box sx={{ color: prices[item.id]?.percent > 0 ? '#0FC679' : '#ff3a33' }}>
                                  {Number(prices[item.id]?.percent).toFixed(3)}%
                                </Box>
                              </Box>

                              <img src={item.chart} style={{ width: '70px' }} />
                            </Box>

                            <Box
                              px={2}
                              py={1}
                              display="flex"
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                background: theme.bg1,
                                border: `1px solid ${theme.text6}`
                              }}
                            >
                              <Box style={{ fontSize: '14px' }}>{item.name}</Box>
                              <Copy toCopy={item.contract}>
                                <span style={{ marginLeft: '4px' }}>
                                  {item.contract.substring(0, 5)}...{item.contract.substring(item.contract.length - 5)}
                                </span>
                              </Copy>
                            </Box>
                          </Box>
                        )
                      })}
                  </InputContainer>
                </AppBody>
              )}
            </Box>
          </Grid>
        )}
        <Grid item lg={4} md={12} sm={12} xs={12}>
          <Box display="flex" style={{ gap: '8px' }}>
            {arrowRight && (
              <Box
                style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%', alignItems: 'center' }}
              >
                <AppBody>
                  <Box
                    display="flex"
                    style={{
                      textTransform: 'none',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 600,
                      height: '47px'
                    }}
                    pb={1.5}
                  >
                    Buy
                  </Box>
                  <Box style={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
                    <Box
                      style={{
                        border: `1px solid ${theme.bg1}`,
                        background: `${theme.bg1}`,
                        borderRadius: '10px',
                        overflow: 'hidden'
                      }}
                    >
                      <Box
                        p={2}
                        display="flex"
                        style={{
                          padding: isSmallScreen ? '15px' : isMediumScreen ? '15px' : isLargeScreen ? '15px' : '15px', // Fallback color
                          alignItems: 'center',
                          gap: '16px'
                        }}
                      >
                        <img src={BitloImg} />
                        <Box style={{ gap: '2px' }}>
                          <Box style={{ fontSize: '14px' }}>Buy or Sell CROPTO tokens</Box>
                          <Box style={{ fontSize: '14px' }}>
                            <span style={{ fontWeight: 600, color: '#696C80' }}>Powered by</span> BITLO
                          </Box>
                          <Link
                            href="https://www.bitlo.com/"
                            target="_blank"
                            style={{ fontSize: '14px', color: '#1AAB25' }}
                          >
                            Buy Now↗
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        border: `1px solid ${theme.bg1}`,
                        background: `${theme.bg1}`,
                        borderRadius: '10px',
                        overflow: 'hidden'
                      }}
                    >
                      <Box
                        p={2}
                        display="flex"
                        style={{
                          padding: isSmallScreen ? '15px' : isMediumScreen ? '15px' : isLargeScreen ? '15px' : '15px', // Fallback color
                          alignItems: 'center',
                          gap: '16px'
                        }}
                      >
                        <img src={CointrImg} />
                        <Box style={{ gap: '2px' }}>
                          <Box style={{ fontSize: '14px' }}>Buy or Sell CROPTO tokens</Box>
                          <Box style={{ fontSize: '14px' }}>
                            <span style={{ fontWeight: 600, color: '#696C80' }}>Powered by</span> CoinTR
                          </Box>
                          <Link
                            href="https://www.cointr.com/"
                            target="_blank"
                            style={{ fontSize: '14px', color: '#1AAB25' }}
                          >
                            Buy Now↗
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AppBody>

                <AppBody>
                  <Box sx={{ minHeight: '240px' }}>
                    <Box
                      display="flex"
                      style={{
                        textTransform: 'none',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontWeight: 600,
                        height: '53px'
                      }}
                      pb={1.5}
                    >
                      News
                      <Box
                        display="flex"
                        style={{
                          textTransform: 'none',
                          alignItems: 'center',
                          gap: '10px',
                          cursor: 'pointer'
                        }}
                        onClick={() => window.open('https://x.com/cropto_io', '_blank')}
                      >
                        @cropto_io
                        <img src={TwitterImg}></img>
                      </Box>
                    </Box>
                    <Box style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                      <Box
                        style={{
                          border: `1px solid ${theme.bg1}`,
                          background: `${theme.bg1}`,
                          borderRadius: '10px',
                          overflow: 'hidden'
                        }}
                      >
                        <Box
                          p={2}
                          display="flex"
                          style={{
                            flexDirection: 'column',
                            gap: '10px',
                            padding: isSmallScreen ? '16px' : isMediumScreen ? '7px' : isLargeScreen ? '15px' : '15px'
                          }}
                        >
                          <Box display="flex" style={{ gap: '15px', alignItems: 'center' }}>
                            <img src={CrowImg} />
                            <Box style={{ fontSize: '14px', color: theme.text7, fontWeight: 600 }}>
                              Cropto Agriculture Tokens
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            style={{ flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}
                          >
                            <Box style={{ gap: '20px' }}>
                              <Box style={{ fontSize: '14px', color: '#696C80', fontWeight: 600 }}>
                                To learn more about Cropto and how you can get involved <br />
                                <br />
                                Follow our X channel for all Cropto updates..
                                <br />
                                <br />
                              </Box>
                              <Link
                                href="https://x.com/cropto_io"
                                target="_blank"
                                style={{ paddingTop: '20px', fontSize: '14px', color: '#1AAB25' }}
                              >
                                Learn more↗
                              </Link>
                            </Box>
                            <img src={NewsImg}></img>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AppBody>

                {isMobile && (
                  <AppBody>
                    <Box
                      display="flex"
                      style={{
                        textTransform: 'none',
                        alignItems: 'center',
                        fontWeight: 600,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '5px'
                      }}
                    // pb={1.5}
                    >
                      <Box style={{ color: '#696C80', fontSize: '13px' }}>JOIN OUR COMMUNITY</Box>
                      <Box style={{ color: theme.text7, fontSize: '21px', fontWeight: 700 }}>
                        Cropto Official Newsletter
                      </Box>
                      <Box style={{ color: '#696C80', fontSize: '13px' }}>
                        Sign-up for the Cropto newsletter to get latest news
                      </Box>
                      <form name="mc-embedded-subscribe-form" onSubmit={onSubmit} style={{ width: '100%' }}>
                        <Box
                          style={{
                            display: 'flex',
                            gap: '10px',
                            paddingTop: '15px',
                            width: '100% !important'
                          }}
                        >
                          <Input
                            className="recipient-address-input"
                            style={{ fontSize: '15px' }}
                            type="email"
                            name="EMAIL"
                            required
                            placeholder="Enter your email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                          <ButtonLight style={{ padding: '8px', maxWidth: '150px' }} type="submit">
                            Subscribe
                          </ButtonLight>
                        </Box>
                      </form>
                    </Box>
                  </AppBody>
                )}
              </Box>
            )}

            <Button
              style={{
                background: theme.bg6,
                borderRadius: '12px',
                height: '48px',
                width: '48px',
                cursor: 'pointer',
                display: isMobile ? 'none' : 'initial'
              }}
              onClick={() => setArrowRight(!arrowRight)}
            >
              <img src={arrowRight ? ArrowRight : ArrowLeft}></img>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            color: '#713200'
          }
        }}
      />
      <KycModal isOpen={!kyc} onDismiss={handleClose} setKYC={setKyc} />
    </>
  )
}
